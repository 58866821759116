import { MenuEntry } from '@pancakeswap-libs/uikit'
import { BsTelegram, BsTwitter, BsFacebook, BsGithub, BsReddit, BsDiscord } from 'react-icons/bs' //eslint-disable-line

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://crypto-swap-farm.mobiloitte.org/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://crypto-swap-amm.mobiloitte.org/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://crypto-swap-amm.mobiloitte.org/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://crypto-swap-farm.mobiloitte.org/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://crypto-swap-farm.mobiloitte.org/pools',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ],
  // },
  // {
  //   label: 'Socials',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Telegram',
  //       href: '/',
  //     },
  //     {
  //       label: 'Twitter',
  //       href: '/',
  //     },
  //     {
  //       label: 'Facebook',
  //       href: '/',
  //     },
  //     {
  //       label: 'Github',
  //       href: '/',
  //     },
  //     {
  //       label: 'Instagram',
  //       href: '/',
  //     },
  //     {
  //       label: 'Reddit',
  //       href: '/',
  //     },
  //     {
  //       label: 'Medium',
  //       href: '/',
  //     },
  //     {
  //       label: 'Discord',
  //       href: '/',
  //     },
  //   ],
  // },
]

export default config

export const socialsLinks: any = [
  {
    label: 'Telegram',
    icon: BsTelegram,
    href: '/',
  },
  {
    label: 'Twitter',
    icon: BsTwitter,
    href: '/',
  },
  {
    label: 'Facebook',
    icon: BsFacebook,
    href: '/',
  },
  {
    label: 'Github',
    icon: BsGithub,
    href: '/',
  },
  {
    label: 'Reddit',
    icon: BsReddit,
    href: '/',
  },
  {
    label: 'Discord',
    icon: BsDiscord,
    href: '/',
  },
]
