import React, { useCallback, useEffect, useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light, dark } from '@pancakeswap-libs/uikit'

const CACHE_KEY = 'IS_DARK'

export interface ThemeContextType {
  isDark: boolean
  toggleTheme: () => void
}

const ThemeContext = React.createContext<ThemeContextType>({ isDark: true, toggleTheme: () => null })

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : true
  })

  const handleSetup = useCallback((event) => {
    if (event && event.data && typeof event.data === 'string' && event.data.startsWith('[iFrameSizer]message:')) {
      const dataStr = event.data.substring('[iFrameSizer]message:'.length)
      const data = JSON.parse(dataStr)
      // console.log('data.isDark', data.isDark)
      setIsDark(() => data.isDark)
    }
  }, [])
  useEffect(() => {
    window.addEventListener('message', handleSetup)
    return () => {
      window.removeEventListener('message', handleSetup)
    }
  }, [handleSetup])

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }
  const lightTheme = {
    ...light,
    button: {
      ...light.button,
      primary: {
        ...light.button.primary,
        background: '#087476',
        backgroundActive: '#087476',
        backgroundHover: '#087476',
      },
      text: {
        ...light.button.text,
        color: '#087476',
      },
      tertiary: {
        ...light.button.tertiary,
        color: '#087476',
      },
      secondary: {
        ...light.button.secondary,
        color: '#087476',
      },
      subtle: {
        ...light.button.subtle,
        background: '#087476',
        backgroundActive: '#087476',
        backgroundHover: '#087476',
      },
    },
    colors: {
      ...light.colors,
      primary: '#087476',
      primaryBright: '#b48849',
      primaryDark: '#b48849',
      textSubtle: '#087476',
      secondary: '#b48849',
      text: '#087476',
    },
  }
  const darkTheme = {
    ...dark,
    alert: { background: '#063f40' },
    card: {
      ...dark.card,
      background: '#063f40',
    },
    modal: { background: '#063f40' },
    nav: { background: '#063f40', hover: '#012b2c' },
    radio: { handleBackground: '#063f40' },
    toggle: { handleBackground: '#063f40' },
    button: {
      ...dark.button,
      primary: {
        ...dark.button.primary,
        background: '#087476',
        backgroundActive: '#087476',
        backgroundHover: '#087476',
      },
      text: {
        ...dark.button.text,
        color: '#087476',
      },
      tertiary: {
        ...dark.button.tertiary,
        color: '#087476',
      },
      secondary: {
        ...dark.button.secondary,
        color: '#087476',
      },
      subtle: {
        ...dark.button.subtle,
        background: '#087476',
        backgroundActive: '#087476',
        backgroundHover: '#087476',
      },
    },
    colors: {
      ...dark.colors,
      primary: '#ffffff',
      primaryBright: '#fef5aa',
      primaryDark: '#ffffff',
      // textSubtle: '#087476',
      secondary: '#011e1e',
      // text: '#087476',
      background: '#2b5b5c',
      card: '#063f40',
      tertiary: '#011e1e',
      input: '#011e1e',
    },
  }
  console.log({ lightTheme, darkTheme })
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? darkTheme : lightTheme}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
